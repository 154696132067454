<template>
    <div>
        <el-dialog
            title="添加描述"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="content" label="描述内容">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                            placeholder="请输入内容"
                            maxlength="500"
                            show-word-limit
                            v-model="form.content">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="pictureList" label="图片">
                        <le-upload-file-img-ly ref="pic" v-model="form.pictureList" :limit='20'></le-upload-file-img-ly>
                    </el-form-item>
                </el-form>
                <p class="a-fw-700 a-c-333">历史描述</p>
                <el-timeline>
                    <el-timeline-item
                        color="#B9B9B9"
                        v-for="(item, index) in tableData"
                        :key="index"
                        :timestamp="item.operateTime">
                        <div>
                            <span class="block" style="padding-bottom: 12px">{{ item.operateUserMobile }}</span>
                            <span>{{ item.content }}</span>
                            <div class="a-flex-rfsc a-flex-wrap">
                                <el-image 
                                    v-for="(ite,idx) in item.pictureList"
                                    :key="idx"
                                    style="width: 100px; height: 100px; border-radius: 8px; margin: 10px 10px 0 0"
                                    :src="ite" 
                                    :preview-src-list="[ite]">
                                </el-image>
                            </div>
                        </div>
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import leUploadFileImgLy from '../../components/form/le-upload-file-img-ly.vue';
    export default {
  components: { leUploadFileImgLy },
        data() {
            return {
                dialogVisible: false,
                form: {
                    pictureList: [],
                    content: ''
                },
                rules: {
                    content: [{required: true, message:'请输入描述', trigger: 'blur'}]
                },
                tableData: []
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                    this.$refs['pic'].fileList = []
                }
            },
        },
        props: ['tableDataItem'],
        methods:{
            getDescList () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getWorkOrderContent,
                    params: {
                        id: this.tableDataItem.id
                    },
                })
                .then(res => {
                    if(res.result.code == 0){
                        this.tableData = res.result.data.map(item=>{
                            return {
                                ...item,
                                pictureList: item.pictureList ? JSON.parse(item.pictureList) : []
                            }
                        })
                    }
                })
                .catch((error) => {
                    this.$message.error(error.result.message)
                });
            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let reqData = {
                            ...this.form,
                            pictureList: this.form.pictureList ? JSON.stringify(this.form.pictureList) : ''
                        }
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$emit('handlerSuccess', reqData)
                            this.dialogVisible = false
                        }).catch(_=>{ })
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
        padding-right: 10px;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>