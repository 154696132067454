<template>
    <div>
        <el-dialog
            title="延期"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item label="原截止日期" v-if="tableDataItem">
                        <span>{{ tableDataItem.endTime }}</span>
                    </el-form-item>
                    <el-form-item prop="endTime" label="变更后" label-width="120px">
                        <el-date-picker
                            v-model="form.endTime"
                            type="datetime"
                            valueFormat="yyyy-MM-dd HH:mm:ss" 
                            :pickerOptions="pickerOptions" 
                            placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input
                            style="width: 400px;"
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                            placeholder="请输入内容"
                            maxlength="200"
                            show-word-limit
                            v-model="form.remark">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                form: {
                    endTime: '',
                    remark: ''
                },
                rules: {
                    endTime: [{required: true, message:'请选择截止时间', trigger: 'change'}]
                },
                pickerOptions: {
                    disabledDate: (time) => {
                        return time.getTime() < Date.now() - 8.64e7 || time.getTime() > Date.now() + 365 * 24 * 3600 * 1000  
                    },
                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            }
        },
        props: ['tableDataItem'],
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let reqData = {
                            ...this.form,
                        }
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$emit('handlerSuccess', reqData)
                            this.dialogVisible = false
                        }).catch(_=>{ })
                    }
                })
                
            },

        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>